import { useEffect } from 'react'

import { useFeatureValue } from '@growthbook/growthbook-react'
import { useLocation } from 'react-router-dom'

import {
  useAddFeatureForUserMutation,
  useGetUserFeaturesQuery,
} from 'src/Redux/A-BFeaturesApi'
import {
  getIsArtistNotFromInvitedLink,
  useGetArtistBalanceHistoryQuery,
} from 'src/Redux/artistBalanceHistoryApi'

import {
  getHasPaidCampaignMetadata,
  useMetadataQuery,
} from '../Redux/metadataApi'

import { useCustomSearchParams } from './useCustomSearchParams'

export const QUICK_CHECKOUT_FEATURE_NAME = 'quick_checkout'
const QUICK_CHECKOUT_SETTINGS_FEATURE_NAME = 'schedule_timer'
export const EXPIRED_TIMER_FEATURE_VALUE = 4
export const QUICK_CHECKOUT_SETTINGS_FEATURE_VALUE = 2
export const QUICK_CHECKOUT_FEATURE_VALUE = 1

export const useCheckoutTimerFeatureFlag = (): void => {
  const { pathname } = useLocation()

  const isTimerOnSettings = useFeatureValue(
    QUICK_CHECKOUT_SETTINGS_FEATURE_NAME,
    0,
  )
  const isTikTokPlatform = pathname.split('/').includes('scheduleTikTok')

  const { data } = useMetadataQuery()

  const { search } = useCustomSearchParams()

  const paymentStatus = search.get('paymentStatus')
  const isFirstPaid = getHasPaidCampaignMetadata(data)
  const { data: featureValue, isSuccess: featuresIsSucceeded } =
    useGetUserFeaturesQuery(null, {
      selectFromResult: (result) => ({
        ...result,
        data: result?.data?.features?.find(
          (item) => item?.name === QUICK_CHECKOUT_FEATURE_NAME,
        )?.variation,
      }),
    })
  const [addFeatureForUser] = useAddFeatureForUserMutation()
  const { isArtistNotFromInvitedLink } = useGetArtistBalanceHistoryQuery(null, {
    selectFromResult: getIsArtistNotFromInvitedLink,
  })

  const isNoFeature = featuresIsSucceeded && !featureValue && featureValue !== 0

  const isQuickCheckoutFeatureAvailable =
    (isNoFeature || featureValue === EXPIRED_TIMER_FEATURE_VALUE) &&
    isArtistNotFromInvitedLink &&
    !isFirstPaid

  const isSuccessPageVisible = paymentStatus === 'success'

  // 1 - old timer on the checkout step, 2 - experiment with timer on the settings step
  const featureVariant =
    isTimerOnSettings && !isTikTokPlatform
      ? QUICK_CHECKOUT_SETTINGS_FEATURE_VALUE
      : QUICK_CHECKOUT_FEATURE_VALUE

  // In case payment is successful, we need to drop the feature for the user
  useEffect(() => {
    if (isSuccessPageVisible) {
      addFeatureForUser({
        featureName: QUICK_CHECKOUT_FEATURE_NAME,
        variation: 0,
      })
    }
  }, [addFeatureForUser, isSuccessPageVisible])

  useEffect(() => {
    if (isQuickCheckoutFeatureAvailable) {
      addFeatureForUser({
        featureName: QUICK_CHECKOUT_FEATURE_NAME,
        variation: featureVariant,
      })
    }
  }, [addFeatureForUser, featureVariant, isQuickCheckoutFeatureAvailable])
}
