import { useMemo, useState } from 'react'

import { useSelector } from 'react-redux'

import { CampaignPlatformTypeEnum } from 'src/Types/index'
import { chargePaypalPayment } from 'src/Redux/payment-process/api-actions'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import {
  getArtistBalance,
  getCountryName,
} from 'src/Redux/auth-process/userSlice/selectors'
import { getCoupon } from 'src/Redux/schedule-process/couponSlice/selectors'
import { getRememberPayPalForFutureFlag } from 'src/Redux/paypalTransactions-process/selectors'
import { setPaymentAlertError } from 'src/Redux/payment-process'
import { PaymentErrors } from 'src/Components/PaymentErrorsAlert'
import { getTiktokPrice } from 'src/Redux/schedule-process/audienceTiktokSlice/selectors'
import { getScheduledTikTokCampaign } from 'src/Redux/schedule-process/tiktokCampaign/selectors'
import { getDefaultStartDate } from 'src/Constants/functions'
import { FIXED_BY } from 'src/Constants/constants'
import {
  campaignsList,
  scheduleCampaign,
  scheduleCampaignTikTok,
} from 'src/Router/routes'
import { useCreateBillingAgreementMutation } from 'src/Redux/billingAgreementApi'
import dayjs from 'src/Helpers/dayjs'

import { ScheduleSteps } from '../Components/ScheduleTabs'

import { useAppDispatch, useAppSelector } from './redux'
import { usePayment } from './usePayment'

interface UsePayPalPayment {
  pay: () => void
  isLoading: boolean
}

export const usePayPalPayment = (
  platformType: CampaignPlatformTypeEnum,
): UsePayPalPayment => {
  const dispatch = useAppDispatch()
  const { calculatePaymentAmounts } = usePayment()
  const [createBillingAgreement] = useCreateBillingAgreementMutation()

  const [isLoading, setIsLoading] = useState(false)
  const price = useAppSelector(getTiktokPrice)
  const scheduledCampaign = useSelector(getScheduledTikTokCampaign)
  const spotifyCampaignDetails = useAppSelector(getCampaignToSchedule)
  const country = useAppSelector(getCountryName)
  const coupon = useAppSelector(getCoupon)
  const balance = useAppSelector(getArtistBalance)
  const isRememberPayPalForFutureFlag = useSelector(
    getRememberPayPalForFutureFlag,
  )

  const campaignToPay = useMemo(() => {
    let campaign

    if (platformType === CampaignPlatformTypeEnum.SPOTIFY) {
      campaign = {
        price: spotifyCampaignDetails.price,
        genres: spotifyCampaignDetails.genres,
        languages: spotifyCampaignDetails.languages,
        id: spotifyCampaignDetails.id,
        startDate: spotifyCampaignDetails.start_date,
      }
    } else {
      campaign = {
        price,
        genres: scheduledCampaign.genres,
        languages: scheduledCampaign.languages,
        id: scheduledCampaign.id,
        startDate: scheduledCampaign.startDate,
      }
    }

    return campaign
  }, [platformType, price, scheduledCampaign, spotifyCampaignDetails])

  const pay = (): void => {
    const currentPaymentAmounts = calculatePaymentAmounts({
      price: campaignToPay.price,
      balance,
      coupon,
      country,
    })

    setIsLoading(true)

    const genresIds = campaignToPay.genres.map((genre) =>
      platformType === CampaignPlatformTypeEnum.TIKTOK
        ? Number(genre.value)
        : Number(genre.id),
    )

    const languagesIds = campaignToPay.languages.map((language) =>
      Number(language.id),
    )

    const startDate =
      dayjs(campaignToPay.startDate).toISOString() || getDefaultStartDate()

    if (isRememberPayPalForFutureFlag) {
      const couponValue = coupon ? `&coupon=${coupon?.value}` : ''
      const schedule =
        platformType === CampaignPlatformTypeEnum.SPOTIFY
          ? scheduleCampaign
          : scheduleCampaignTikTok
      createBillingAgreement({
        cancelUrl: `${window.location.origin}${campaignsList}${schedule}/${campaignToPay.id}?step=${ScheduleSteps.SETTINGS}&startDate=${startDate}&price=${campaignToPay.price}${couponValue}&paymentType=paypal&error=save_token_error_campaign_payment`,
        successUrl: `${window.location.origin}${campaignsList}${schedule}/${campaignToPay.id}?step=${ScheduleSteps.CHECKOUT}&startDate=${startDate}&price=${campaignToPay.price}${couponValue}&paymentType=paypal&autopay=true`,
      })
        .unwrap()
        .then((res) => {
          window.location.href = res.link
          return res
        })
        .catch(() => {
          dispatch(setPaymentAlertError(PaymentErrors.DEFAULT))
        })
        .finally(() => {
          setIsLoading(false)
        })
      return
    }

    currentPaymentAmounts &&
      void dispatch(
        chargePaypalPayment({
          amount: currentPaymentAmounts?.total,
          campaigns: [
            {
              type: platformType,
              genres: genresIds,
              languages: languagesIds,
              amount: Number(campaignToPay.price.toFixed(FIXED_BY)),
              startDate:
                campaignToPay.startDate?.toString() || getDefaultStartDate(),
              campaignId: Number(campaignToPay?.id),
            },
          ],
          country: country || '',
          couponValue: coupon?.value,
          usedBalance: currentPaymentAmounts.balanceUseAmount,
        }),
      )
        .unwrap()
        .then((res) => {
          window.location.href = res.link
          return res
        })
        .catch(() => {
          dispatch(setPaymentAlertError(PaymentErrors.DEFAULT))
        })
        .finally(() => {
          setIsLoading(false)
        })
  }

  return {
    isLoading,
    pay,
  }
}
