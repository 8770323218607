import { FC } from 'react'

import styled from 'styled-components'

import { variables } from 'src/Styled/variables'

const Container = styled.div`
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.secondaryBorder};
`

const TimerText = styled.span`
  color: ${({ theme }) => theme.common.dangerText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
  width: 32px;
`

const Text = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
`

const Title = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
`

interface CheckoutTimerTooltipProps {
  countdown: string
  className?: string
}

export const CheckoutTimerTooltip: FC<CheckoutTimerTooltipProps> = ({
  countdown,
  className,
}) => (
  <Container className={className}>
    <TimerText>{countdown}</TimerText>
    <TextContainer>
      <Title>Limited Offer! \uD83C\uDF81</Title>
      <Text>
        Launch your campaign in 15 minutes and get a $20 bonus for the next
        campaign!
      </Text>
    </TextContainer>
  </Container>
)
