import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { SPOTIFY_BUDGET_STEP } from 'src/Constants/constants'
import { useCampaignPrice } from 'src/Containers/ScheduleSpotifyWrapper/components/SpotifySettings/helpers'
import { BudgetSlider } from 'src/Containers/ui/BudgetSlider'
import { MAX_TABLET_WIDTH, colors } from 'src/Styled/variables'
import { ScheduleBudgetInput } from 'src/Components/ScheduleBudgetInput'
import { PrimaryScheduleSubscription } from 'src/Components/PrimaryScheduleSubscription'

import { useBonusTooltipSettings } from '../../../../Hooks/useBonusTooltipSettings'

import { SpotifySettingsContinueButton } from './SpotifySettingsContinueButton'
import { SpotifySettingBudgetEstimation } from './SpotifySettingBudgetEstimation'
import { SpotifySettingsContinueButtonWithTooltip } from './SpotifySettingsContinueButtonWithTooltip'

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    width: 400px;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const SliderContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 34px;
  margin-left: 37px;
  margin-right: 37px;

  .ant-slider {
    padding-inline: 0px;
  }
  .ant-slider .ant-slider-handle::before {
    width: 48px;
    height: 48px;
    left: -12px;
    top: -12px;
  }
`
const BudgetSliderWrapper = styled.div`
  display: flex;
  gap: 8px;
  height: 100%;
`
const BudgetSliderSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
const BudgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledBudgetSlider = styled(BudgetSlider)`
  .ant-slider-handle {
    touch-action: none;
    will-change: transform;
  }
  .ant-slider-rail {
    scroll-behavior: smooth;
  }
  &.ant-slider-vertical {
    width: 6px;
    margin: 0;
  }
  & > .ant-slider-rail {
    height: 100%;
    width: 6px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.progress.primaryBg};
  }

  .ant-slider-mark {
    margin-left: -12px;
    inset-inline-start: 0;
    white-space: nowrap;
    .ant-slider-mark-text {
      bottom: 97% !important;
      line-height: 150%;

      &:first-child {
        width: auto;
        transform: translate(-10%, 100%) !important;
        bottom: -3% !important;
        color: ${({ theme }) => theme.common.primaryText};
      }
      &:last-child {
        width: auto;
        transform: translate(-10%, 100%) !important;
        bottom: 112% !important;
        color: ${({ theme }) => theme.common.primaryText};
      }
    }
  }
`
const SliderTrackStyles = {
  width: 6,
  borderRadius: 6,
  backgroundColor: colors.green,
}

const SliderHandleStyles = {
  marginLeft: -4.5,
  height: 16,
  width: 16,
  border: 'none',
  borderRadius: 12,
  backgroundColor: colors.white,
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
}
export const SpotifySettingsBudget: FC = () => {
  const { t } = useTranslation()
  const { isNextTimeBonusSettings } = useBonusTooltipSettings()
  const {
    savePrice,
    rate,
    currencySymbol,
    min,
    max,
    valueInput,
    valueSlider,
    setValueInput,
    setValueSlider,
  } = useCampaignPrice()

  const handleSliderChange = (value: number): void => {
    setValueInput(Math.round(value * rate))
    setValueSlider(value)
  }
  const handleSliderChangeCompleted = (value: number): void => {
    savePrice(value)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const substr = event.target.value.substring(
      currencySymbol.length,
      event.target.value.length,
    )
    const inputValue = Number(substr)
    if (isNaN(inputValue)) {
      return
    }
    setValueInput(inputValue)
    savePrice(Math.round(inputValue / rate))
  }

  const marks = {
    [min]: `${currencySymbol}${Math.round(min * rate).toLocaleString('en-US')}`,
    [max]: `${currencySymbol}${Math.round(max * rate).toLocaleString('en-US')}`,
  }

  return (
    <Container>
      <Title>{t('scheduleCampaignPage.SetUp')}</Title>
      <BudgetSliderWrapper>
        <SliderContainer>
          <StyledBudgetSlider
            value={valueSlider}
            vertical
            marks={marks}
            tooltip={{ formatter: null }}
            step={SPOTIFY_BUDGET_STEP}
            max={max}
            min={min}
            styles={{
              track: SliderTrackStyles,
              handle: SliderHandleStyles,
            }}
            onChangeComplete={handleSliderChangeCompleted}
            onChange={handleSliderChange}
          />
        </SliderContainer>
        <BudgetSliderSideContainer>
          <BudgetContainer>
            <ScheduleBudgetInput
              role='budget-input'
              value={`${currencySymbol}${valueInput}`}
              onChange={handleInputChange}
            />
          </BudgetContainer>
          <PrimaryScheduleSubscription
            symbol={currencySymbol}
            price={valueInput}
          />
          <SpotifySettingBudgetEstimation />
        </BudgetSliderSideContainer>
      </BudgetSliderWrapper>

      {isNextTimeBonusSettings ? (
        <SpotifySettingsContinueButtonWithTooltip />
      ) : (
        <SpotifySettingsContinueButton />
      )}
    </Container>
  )
}
