import React, { FC, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { getIsLanguageActive } from 'src/Containers/ScheduleSpotifyWrapper/components/SpotifySettings/helpers'
import { useAppSelector } from 'src/Hooks/redux'
import { useCheckoutEvents } from 'src/Hooks/useCheckoutEvents'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import {
  getCampaignToSchedule,
  getIsUpdateCampaignLoading,
} from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { useGetBudgetRangeQuery } from 'src/Redux/spotifyBudgetRangeApi'
import { CampaignPlatformTypeEnum } from 'src/Types'

import { MAX_MOBILE_WIDTH } from '../../../../Styled/variables'
import { CheckoutTimerTooltip } from '../../../../Components/CheckoutTimerTooltip'
import { getFormattedCheckoutTimer } from '../../../../Redux/checkoutCountdown-process'
import { useBonusTooltipSettings } from '../../../../Hooks/useBonusTooltipSettings'

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid ${({ theme }) => theme.button.primaryText};
`

const StyledTooltip = styled(CheckoutTimerTooltip)`
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    position: absolute;
    top: -101px;
    height: 89px;
    background-color: ${({ theme }) => theme.common.primaryBg};
    width: calc(100% - 32px);
  }
`

const ButtonContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    display: flex;
    width: 100%;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: ${({ theme }) => theme.common.primaryBg};
    box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12);
    border-top: 1px solid ${({ theme }) => theme.common.primaryBorder};

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export const SpotifySettingsContinueButtonWithTooltip: FC = () => {
  const { isNextTimeBonusSettings, startNextTimeBonusCountdown } =
    useBonusTooltipSettings()

  useEffect(() => {
    startNextTimeBonusCountdown()
  }, [startNextTimeBonusCountdown])

  useEffect(() => {
    const wrapper = document.querySelector(
      '[class*="SpotifyScheduleSettingsPage__Wrapper"]',
    )
    if (
      wrapper &&
      !wrapper.querySelector('.PaddingBlock') &&
      isNextTimeBonusSettings
    ) {
      const paddingBlock = document.createElement('div')
      paddingBlock.className = 'PaddingBlock'
      paddingBlock.style.height = '135px'
      wrapper.appendChild(paddingBlock)
    }
  }, [isNextTimeBonusSettings])

  const { t } = useTranslation()
  const { handleCheckoutStartEvent } = useCheckoutEvents()
  const countdown = useAppSelector(getFormattedCheckoutTimer)
  const { number: campaignId } = useParams()

  const isUpdateCampaignLoading = useAppSelector(getIsUpdateCampaignLoading)
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const { isError: budgetRangeError } = useGetBudgetRangeQuery(campaignId, {
    skip: !campaignId,
  })

  const { setSearch } = useCustomSearchParams()
  const isLanguageActive = getIsLanguageActive(campaignDetails.languages)
  if (!campaignDetails.genres.length || isLanguageActive) {
    return null
  }
  const handleClick = (): void => {
    setSearch({ step: ScheduleSteps.CHECKOUT })

    handleCheckoutStartEvent({
      platformName: CampaignPlatformTypeEnum.SPOTIFY,
      id: Number(campaignDetails.id),
      campaignType: campaignDetails.type,
      isFeed: campaignDetails.is_feed,
      genres: campaignDetails.genres.map((genre) => genre.name),
      languages: campaignDetails.languages.map((language) => language.name),
      startDate: (campaignDetails.start_date || '').toString(),
      resultPrice: campaignDetails.price,
    })
  }
  const hasPrice = campaignDetails.price > 0
  const isDisabled = budgetRangeError || !hasPrice || isUpdateCampaignLoading

  return (
    <ButtonContainer>
      {isNextTimeBonusSettings && <StyledTooltip countdown={countdown} />}
      <StyledPrimaryButton onClick={handleClick} disabled={isDisabled}>
        {t('scheduleCampaignPage.continue')}
      </StyledPrimaryButton>
    </ButtonContainer>
  )
}
