import { useCallback } from 'react'

import { useDebouncedCallback } from 'use-debounce'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getSpotifyToken } from 'src/Redux/spotifyToken-process/selectors'
import { setTracksToInitialState } from 'src/Redux/spotifyTracks-process'
import {
  setTikTokTrackFromSearchList,
  setTiktokTrackToInitialState,
  setTiktokTracksToInitialState,
  ExistingTikTokCampaign,
} from 'src/Redux/tiktokTrack-process'
import { CampaignPlatformTypeEnum, TikTokTrack, Track } from 'src/Types'
import { usePageViewEvent } from 'src/Hooks/Analytics/useTrackEvents'
import {
  checkTrackToCreateCampaign,
  getSpotifyTracks,
} from 'src/Redux/spotifyTracks-process/api-actions'
import { searchTikTokTrackAuth } from 'src/Redux/tiktokTrack-process/api-actions'
import { getIsTikTokTrackUrl } from 'src/Constants/functions'
import {
  DEBOUNCE_TIMER_1000,
  DEBOUNCE_TIMER_300,
} from 'src/Constants/constants'
import {
  getAllSearchErrorSelector,
  getIsLoadingTikTok,
  getTiktokTrackErrorExistingTiktokCampaignSelector,
  getTikTokTrackSelector,
  getTikTokTracksSelector,
} from 'src/Redux/tiktokTrack-process/selectors'
import {
  getIsLoadingSpotifyTracks,
  getTracks,
} from 'src/Redux/spotifyTracks-process/selectors'
import { setSchedulePlatform } from 'src/Redux/campaign-process/spotifyCampaignSchedule-process'
import { getSpotifyTrack } from 'src/Redux/track-process/api-actions'
import { getSpotifyAccessToken } from 'src/Redux/spotifyToken-process/api-actions'
import { getSpotifyTrackSelector } from 'src/Redux/track-process/selectors'
import { setTrackToInitialState } from 'src/Redux/track-process'
import { SearchTrackFormDataProps } from 'src/Components/SearchForm/TrackSearchAuth'

import { ArtistRoutesEnum } from '../../Router/ArtistComponentsNewUI'
import { useSearchOneTrackAuth } from '../../Pages/Artist/CreateCampaignPage/AuthPages/SearchTrackAuth/useSearchTrackAuth'

interface UseSearchTrackResponse {
  onClearTracks: () => void
  onCheckTrack: (url: string) => void
  onChoseTikTokTrack: (track: TikTokTrack) => void
  onTrackSearchHandler: ({ searchInput }: SearchTrackFormDataProps) => void
  tracks: TikTokTrack[] | Track[]
  hasSelectedTrack: boolean
  error?: string
  loading: boolean
  existingTikTokCampaign?: ExistingTikTokCampaign | null
  tikTokSearchErrorMessage: string
  tikTokExistingCampaign: ExistingTikTokCampaign | null
}

export const useSearchTrackAuth = (
  platform?: CampaignPlatformTypeEnum,
): UseSearchTrackResponse => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector(getSpotifyToken)
  const tikTokTracks = useAppSelector(getTikTokTracksSelector)
  const spotifyTracks = useAppSelector(getTracks)
  const searchError = useAppSelector(getAllSearchErrorSelector)
  const existingTikTokCampaign = useAppSelector(
    getTiktokTrackErrorExistingTiktokCampaignSelector,
  )
  const isLoadingTikTok = useAppSelector(getIsLoadingTikTok)
  const isLoadingSpotify = useAppSelector(getIsLoadingSpotifyTracks)
  const tikTokTrack = useAppSelector(getTikTokTrackSelector)
  const spotifyTrack = useAppSelector(getSpotifyTrackSelector)

  const {
    searchTikTokTrack,
    tikTokSearchErrorMessage,
    tikTokExistingCampaign,
  } = useSearchOneTrackAuth()

  const {
    enteredTrackUrlEventHandler,
    startedEnterTrackEventHandler,
    trackAddedEventHandler,
  } = usePageViewEvent()

  const onClearTracks = useCallback((): void => {
    dispatch(setTracksToInitialState())
    dispatch(setTiktokTracksToInitialState())
    dispatch(setTrackToInitialState())
    dispatch(setTiktokTrackToInitialState())
  }, [dispatch])

  const searchTracks = ({
    searchInput: searchInputValue,
  }: SearchTrackFormDataProps): void => {
    if (
      platform === CampaignPlatformTypeEnum.SPOTIFY &&
      accessToken &&
      searchInputValue
    ) {
      void dispatch(
        getSpotifyTracks({ search: searchInputValue, token: accessToken }),
      )
      return
    }

    if (platform === CampaignPlatformTypeEnum.TIKTOK) {
      void dispatch(searchTikTokTrackAuth({ searchText: searchInputValue }))
    }
  }
  const onTrackSearchHandler = useDebouncedCallback(
    ({ searchInput }: SearchTrackFormDataProps) => {
      if (!searchInput) {
        onClearTracks()
        return
      }
      platform && searchTracks({ searchInput })

      if (getIsTikTokTrackUrl(searchInput)) {
        enteredTrackUrlEventHandler()
      } else {
        startedEnterTrackEventHandler()
      }
    },
    DEBOUNCE_TIMER_1000,
  )

  const onCheckTrack = useDebouncedCallback(async (url: string) => {
    const isSpotifyPlatform =
      platform === CampaignPlatformTypeEnum.SPOTIFY && accessToken
    const isTikTokPlatform = platform === CampaignPlatformTypeEnum.TIKTOK

    if (isSpotifyPlatform) {
      try {
        await dispatch(
          checkTrackToCreateCampaign({
            trackURL: `https://api.spotify.com/v1/tracks/${url}`,
          }),
        ).unwrap()
        const res = await dispatch(
          getSpotifyTrack({ url, token: accessToken }),
        ).unwrap()
        trackAddedEventHandler({
          artistName: res?.artists[0]?.name,
          platformType: CampaignPlatformTypeEnum.SPOTIFY,
          trackDuration: res?.duration_ms,
          trackId: res?.id,
          trackName: res?.name,
        })

        dispatch(setTracksToInitialState())
        dispatch(setSchedulePlatform(null))

        navigate(
          `${ArtistRoutesEnum.ChooseCreateCampaignPlatform}/${platform}/${url}`,
        )
      } catch {
        void dispatch(getSpotifyAccessToken(false))
      }
    }

    if (isTikTokPlatform) {
      searchTikTokTrack({
        searchText: `https://www.tiktok.com/music/-${url}`,
      })
        .unwrap()
        .then((res) => {
          trackAddedEventHandler({
            artistName: res[0].trackAuthorName,
            platformType: CampaignPlatformTypeEnum.TIKTOK,
            trackDuration: res[0].trackDuration,
            trackId: res[0].trackTiktokId,
            trackName: res[0].trackTitle,
          })

          navigate(
            `${ArtistRoutesEnum.ChooseCreateCampaignPlatform}/${platform}/${url}`,
          )
        })
    }
  }, DEBOUNCE_TIMER_300)

  const onChoseTikTokTrack = useDebouncedCallback(
    async (track: TikTokTrack) => {
      trackAddedEventHandler({
        artistName: track.trackAuthorName,
        platformType: CampaignPlatformTypeEnum.TIKTOK,
        trackDuration: track.trackDuration,
        trackId: track.trackTiktokId,
        trackName: track.trackTitle,
      })
      dispatch(setTikTokTrackFromSearchList(track))
      dispatch(setSchedulePlatform(null))
      dispatch(setTiktokTracksToInitialState())
    },
    DEBOUNCE_TIMER_300,
  )

  const tracks =
    platform === CampaignPlatformTypeEnum.SPOTIFY ? spotifyTracks : tikTokTracks
  const hasSelectedTrack = Boolean(spotifyTrack || tikTokTrack)

  return {
    hasSelectedTrack,
    onClearTracks,
    existingTikTokCampaign,
    onCheckTrack,
    onChoseTikTokTrack,
    onTrackSearchHandler,
    error: searchError || '',
    tracks: tracks || [],
    loading: isLoadingTikTok || isLoadingSpotify,
    tikTokSearchErrorMessage,
    tikTokExistingCampaign,
  }
}
