import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import {
  getHasPaidCampaignMetadata,
  useMetadataQuery,
} from 'src/Redux/metadataApi'
import {
  getCheckoutTimer,
  startCountdown,
  updateCountdown,
} from 'src/Redux/checkoutCountdown-process'
import {
  useAddFeatureForUserMutation,
  useGetUserFeaturesQuery,
} from 'src/Redux/A-BFeaturesApi'
import { getIsUserReferral } from 'src/Redux/auth-process/userSlice/selectors'

import { useAppDispatch } from './redux'
import {
  EXPIRED_TIMER_FEATURE_VALUE,
  QUICK_CHECKOUT_FEATURE_NAME,
  QUICK_CHECKOUT_SETTINGS_FEATURE_VALUE,
} from './useCheckoutTimerFeratureFlag'

const TIMEOUT = 1000
const COUNTDOWN_SETTINGS_VALUE = 900

interface UseBonusTooltipTimerInterface {
  isNextTimeBonusSettings: boolean
  startNextTimeBonusCountdown: () => void
}

export const useBonusTooltipSettings = (): UseBonusTooltipTimerInterface => {
  const dispatch = useAppDispatch()

  const { data: featureValue } = useGetUserFeaturesQuery(null, {
    selectFromResult: (result) => ({
      ...result,
      data: result?.data?.features?.find(
        (item) => item?.name === QUICK_CHECKOUT_FEATURE_NAME,
      )?.variation,
    }),
  })

  // eslint-disable-next-line no-undefined
  const { data } = useMetadataQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const [addFeatureForUser] = useAddFeatureForUserMutation()

  const isFirstPaid = getHasPaidCampaignMetadata(data)
  const isUserReferral = useSelector(getIsUserReferral)
  const countdown = useSelector(getCheckoutTimer)

  const isBonusConditions = !isUserReferral && !isFirstPaid

  const isNextTimeBonusSettings =
    isBonusConditions && featureValue === QUICK_CHECKOUT_SETTINGS_FEATURE_VALUE

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isNextTimeBonusSettings) {
      timer = setInterval(() => {
        if (countdown === 0) {
          addFeatureForUser({
            featureName: QUICK_CHECKOUT_FEATURE_NAME,
            variation: EXPIRED_TIMER_FEATURE_VALUE,
          })
          clearInterval(timer)
          dispatch(updateCountdown(null))
        } else {
          countdown && dispatch(updateCountdown(countdown - 1))
        }
      }, TIMEOUT)
    }

    return () => clearInterval(timer)
  }, [addFeatureForUser, countdown, dispatch, isNextTimeBonusSettings])

  const startNextTimeBonusCountdown = (): void => {
    isNextTimeBonusSettings &&
      dispatch(startCountdown(COUNTDOWN_SETTINGS_VALUE))
  }

  return {
    isNextTimeBonusSettings,
    startNextTimeBonusCountdown,
  }
}
