import { useEffect } from 'react'

import { useBackFromPaymentPageSpotify } from 'src/Containers/ScheduleSpotifyWrapper/helpers/useBackFromPaymentPageSpotify'
import { useCheckSpotifyCampaignStatus } from 'src/Containers/ScheduleSpotifyWrapper/helpers/useCheckSpotifyCampaignStatus'
import { useSidebarVisibility } from 'src/Hooks/useSidebarVisibility'
import { useDefaultSpotifyCampaignStartDate } from 'src/Containers/ScheduleSpotifyWrapper/helpers/useDefaultSpotifyCampaignStartDate'
import { useCheckoutTimerFeatureFlag } from 'src/Hooks/useCheckoutTimerFeratureFlag'
import { useBonusTooltipTimer } from 'src/Hooks/useBonusTooltip'

import { updateCountdown } from '../../Redux/checkoutCountdown-process'
import { useAppDispatch } from '../../Hooks/redux'

import { useScheduleStartEvent } from './helpers/useScheduleStartEvent'
import { useIsCurrentSpotifyCampaign } from './helpers/useIsCurrentCampaign'

export const useScheduleSpotifyCampaign = (): void => {
  const dispatch = useAppDispatch()

  useIsCurrentSpotifyCampaign()
  useBackFromPaymentPageSpotify()
  useSidebarVisibility()
  useCheckSpotifyCampaignStatus()
  useDefaultSpotifyCampaignStartDate()
  useScheduleStartEvent()

  useBonusTooltipTimer()
  useCheckoutTimerFeatureFlag()

  useEffect(
    () => () => {
      dispatch(updateCountdown(null))
    },
    [dispatch],
  )
}
